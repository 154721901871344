import React from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { FaBriefcase, FaComputer, FaNewspaper , FaSchool, FaGlobe, FaHandHoldingHeart } from 'react-icons/fa6';
import AnimText from './text';

function TimelineElement({title, subtitle, date, icon, positions}) {
    return (
        <VerticalTimelineElement
            className="text-[#F5EFE6] xs:text-2xl"
            contentStyle={{ background: '#F5EFE6', color: '#102C57', borderRadius: '12px', fontWeight: 'bold' }}
            // date={date}
            iconStyle={{ background: '#102C57', color: '#F5EFE6' }}
            icon={icon}
        >
            <h3 className="vertical-timeline-element-title text-[#1A2130] font-bold pb-2">{title}</h3>
            {positions && positions.map((position, index) => (
                <>
                    <h4 className="vertical-timeline-element-subtitle text-[#1A2130] font-medium">{position.title}</h4>
                    <h5 className="vertical-timeline-element-subtitle text-[#1A2130] text-xl font-normal pb-2">{position.date}</h5>
                </>
            ))}
        </VerticalTimelineElement>
    );
}

export default function Activities() {
    return (
    <div className='bg-[#1A2130] rounded-b-3xl' id='activities'>
        <div className="flex min-h-screen flex-col xxs:px-8 xs:px-12 sm:px-20 py-16">
            <div className="font-bold text-[#F5EFE6] xxs:text-3xl xs:text-6xl sm:text-7xl md:text-8xl lg:text-8xl">
                <AnimText phrase={'leadership and activities'} />
            </div>
            <div className="xxs:text-2xl xs:text-4xl font-light text-[#F5EFE6] pt-4 pb-12 xl:px-8 xl:text-right">
                <AnimText phrase={'Extracurriculars and interests.'} />
            </div>
            <VerticalTimeline        
            >
                <TimelineElement
                    title='UPLB Career Assistance Program'
                    positions={[
                        {
                            title: 'Operations Officer',
                            date: '2024 - Present'
                        }
                    ]}
                    icon={<FaBriefcase/>}
                />
                <TimelineElement
                    title="NDAS Phils. Inc."
                    subtitle='Software Developer Contractor'
                    date='2024 - Present'
                    positions={[
                        {
                            title: 'Software Developer Contractor',
                            date: '2024 - Present'
                        }
                    ]}
                    icon={<FaBriefcase/>}
                />
                <TimelineElement
                    title='UP Internet Freedom Network'
                    positions={[
                        {
                            title: 'Member',
                            date: '2023 - Present'
                        }
                    ]}
                    icon={<FaGlobe/>}
                />
                <TimelineElement
                    title="Young Software Engineers' Society"
                    subtitle='Member'
                    date='2023 - Present'
                    positions={[
                        {
                            title: 'Member',
                            date: '2023 - Present'
                            },
                        {
                            title: 'Promotions Committee Head',
                            date: '2023 - 2024'
                        },
                        ]}
                    icon={<FaComputer/>}
                />
                <TimelineElement
                    title='UPLB Perspective'
                    subtitle='Assistant Technical Director'
                    date='2023 - Present'
                    positions={[
                            {
                                title: 'Assistant Technical Director',
                                date: '2024 - Present'
                            },
                            {
                                title: 'Broadcast Staff',
                                date: '2023 - 2024'
                            }
                        ]}
                    icon={<FaNewspaper />}
                />
                <TimelineElement
                    title='College Admission Mentors for Peers PH'
                    positions={[
                        {
                            title: 'Mentor',
                            date: '2023 - 2024'
                        }
                    
                    ]}
                    icon={<FaSchool/>}
                />
                <TimelineElement
                    title='iACADEMY SiLAKBO'
                    positions={[
                        {
                            title: 'President',
                            date: '2021 - 2022'
                        },
                        {
                            title: 'Social Advocacy Member',
                            date: '2020 - 2021'
                        }
                    
                    ]}
                    icon={<FaHandHoldingHeart/>}
                />
                <TimelineElement
                    title='iACADEMY Junior Software Developers'
                    positions={[
                        {
                            title: 'Vice President for External Affairs',
                            date: '2021 - 2022'
                        },
                        {
                            title: 'Member',
                            date: '2020 - 2021'
                        }
                    ]}
                    icon={<FaComputer/>}
                />
            </VerticalTimeline>
        </div>
    </div>
    );
}