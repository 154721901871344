import { useState, useEffect, useRef } from "react";
import emailjs from '@emailjs/browser';
import Popup from './popup';


export const Contact = () => {
  const [showPopup, setShowPopup] = useState(false);
  const form = useRef();
  const [time, setTime] = useState(new Date());

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_mzapc0m', 'template_cy4e7gm', form.current, {
        publicKey: process.env.REACT_APP_EMAILJS_PUBLIC_KEY,
      })
      .then(
        () => {
            console.log('SUCCESS!');
            setShowPopup(true);
            form.current.reset();
        },
        (error) => {
            alert('An error occurred, Please try again');
            console.log('FAILED...', error.text);
        },
      );
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <div className='pt-8 bg-[#F5EFE6] min-h-screen flex-grow flex flex-col'>
      <div className="flex-grow flex flex-col text-[#1A2130]">
          <div className="font-bold px-20 pt-4 text-center xxs:text-4xl xs:text-6xl sm:text-7xl md:text-8xl lg:text-8xl">
              contact
          </div>
          <div className="flex-grow flex flex-col md:flex-row pt-8 pb-8 xxs:px-12 xs:px-20">
              <div className="flex-grow flex flex-col w-[65vw] text-[#1A2130] xs:pt-8 lg:pt-12 xl:pt-16 pr-4">
                  <div className="xxs:text-2xl xs:text-4xl lg:text-6xl font-bold text-left" id="contact">
                      say hi!
                  </div>
                  <form ref={form} onSubmit={sendEmail} className='flex flex-col space-y-4 xxs:pt-4 xs:pt-8 lg:pt-16 text-2xl'>
                <div className='flex xxs:flex-col lg:flex-row lg:space-x-4'>
                  <div class="relative z-0 w-full mb-5 group">
                    <input type="text" name="from_name" id="floating_name" class="block py-2.5 px-0 w-full text-[#1A2130] bg-transparent border-0 border-b-2 border-[#102C57] appearance-none dark:text-[#1A2130] dark:border-[#102C57] dark:focus:border-[#102C57] focus:outline-none focus:ring-0 focus:border-[#102C57] peer xxs:text-lg md:text-3xl" placeholder=" " required />
                    <label for="floating_name" class="peer-focus:font-medium absolute text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-8 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-[#102C57] peer-focus:dark:text-[#102C57] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">name</label>
                  </div>
                  <div class="relative z-0 w-full mb-5 group">
                    <input type="email" name="from_email" id="floating_email" class="block py-2.5 px-0 w-full text-[#1A2130] bg-transparent border-0 border-b-2 border-[#102C57] appearance-none dark:text-[#1A2130] dark:border-[#102C57] dark:focus:border-[#102C57] focus:outline-none focus:ring-0 focus:border-[#102C57] peer xxs:text-lg md:text-3xl" placeholder=" " required />
                    <label for="floating_email" class="peer-focus:font-medium absolute text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-8 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-[#102C57] peer-focus:dark:text-[#102C57] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">email</label>
                  </div>
                </div>
                <div class="relative z-0 w-full mb-5 group xs:py-6">
                  <textarea name="message" id="floating_message" rows="7" class="block py-2.5 px-0 w-full text-[#1A2130] bg-transparent border-0 border-b-2 border-[#102C57] appearance-none dark:text-[#1A2130] dark:border-[#102C57] dark:focus:border-[#102C57] focus:outline-none focus:ring-0 focus:border-[#102C57] peer xxs:text-lg md:text-3xl" placeholder=" " required></textarea>
                  <label for="floating_message" class="peer-focus:font-medium absolute text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-8 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-[#102C57] peer-focus:dark:text-[#102C57] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">message</label>
                </div>
                <div>
                  <input className='text-[#F5EFE6] text-xl bg-[#1A2130] hover:bg-[#102C57] transform transition duration-500 hover:scale-110 focus:ring-4 focus:outline-none focus:ring-blue-300 font-normal rounded-full px-5 py-2 text-center dark:bg-[#1A2130] dark:hover:bg-[#102C57] dark:focus:ring-blue-800 w-auto cursor-pointer' type="submit" value="send message" />
                </div>
              </form>
              </div>
              <div className="flex-grow flex flex-col text-[#1A2130] xxs:pt-12 xl:pt-16 md:pl-8">
                <div className='xxs:text-2xl sm:text-3xl font-bold'>
                  Contact Details
                </div>
                <ul>
                    <li className="xxs:py-1 xs:py-2 sm:py-4">
                        <a href="#" className="contact-hover xs:text-xl sm:text-2xl font-normal" data-after="hello@millaizha.com">
                            <span className=''>hello@millaizha.com</span>
                        </a>
                    </li>
                    <li className="pb-4">
                        <a href="#" className="contact-hover xs:text-xl sm:text-2xl font-normal" data-after="mdvalencia3@up.edu.ph">
                            <span className=''>mdvalencia3@up.edu.ph</span>
                        </a>
                    </li>
                </ul>
                <div className='xs:text-2xl sm:text-3xl font-bold xs:pt-4 md:pt-16'>
                  My Socials
                </div>
                <ul>
                    <li className="xxs:py-1 xs:py-2 sm:py-4">
                        <a href="http://www.github.com/millaizha" className="contact-hover xs:text-xl sm:text-2xl font-normal" data-after="GitHub">
                            <span className=''>GitHub</span>
                        </a>
                    </li>
                    <li className="pb-4">
                        <a href="http://www.linkedin.com/in/millaizha" target="_blank" className="contact-hover xs:text-xl sm:text-2xl font-normal" data-after="LinkedIn">
                            <span className=''>LinkedIn</span>
                        </a>
                    </li>
                </ul>
                <div className='xxs:py-1 xs:text-2xl sm:text-3xl font-bold xs:pt-4 md:pt-16'>
                  Location
                </div>
                <div className='xs:text-xl sm:text-2xl font-normal xs:py-2 sm:py-4'>
                  Bacoor, Philippines
                </div>
                <div className='xs:text-xl sm:text-2xl font-normal'>
                  {time.toLocaleString('en-US', {
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                    hour12: true,
                    timeZone: 'Asia/Shanghai'
                  })}
                </div>
              </div>
          </div>
          <div className="xs:text-lg sm:text-xl font-bold text-center xs:mb-8 xl:mb-16 text-[#1A2130]">
              mill valencia © 2025
          </div>
      </div>
    </div>
  );
};
